var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isCustom)?_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"mb-4 mw-lang",attrs:{"items":_vm.languages,"label":_vm.$t('Language'),"hide-details":"auto"},model:{value:(_vm.currentLanguage),callback:function ($$v) {_vm.currentLanguage=$$v},expression:"currentLanguage"}})],1),_c('v-col',[_vm._l((_vm.languages),function(lang,index){return [(_vm.cardCheck && lang == _vm.currentLanguage)?_c('dragAndDrop',{key:index,attrs:{"multiple":false,"uploadUrl":"/onboardingsteps/upload-image","needsUploadBtn":false,"autoUploadAfterSelect":true,"accepts":[],"options":{
                height: '500',
              }},on:{"setSnackbar":function($event){return _vm.$emit('setSnackbar', $event)},"returnFilename":function($event){_vm.onboardingItem['Images'][_vm.currentLanguage] = $event[0]}}}):(lang == _vm.currentLanguage)?_c('v-img',{key:index,attrs:{"src":_vm.serverUrl +
                '/images/onboarding/' +
                _vm.onboardingItem['Images'][_vm.currentLanguage] +
                '?token=' +
                _vm.userToken}}):_vm._e()]})],2)],1)],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"mb-4 mw-lang",attrs:{"items":_vm.languages,"label":_vm.$t('Language'),"hide-details":"auto"},model:{value:(_vm.currentLanguage),callback:function ($$v) {_vm.currentLanguage=$$v},expression:"currentLanguage"}})],1),_c('v-col',[_vm._l((_vm.languages),function(lang,index){return [(lang == _vm.currentLanguage)?_c('editor',{key:index,attrs:{"api-key":"f18i0ywzo341k9tx3r3vjsnoghnomdrqbhx2qqi5lnul1dbh","init":{
                height: 500,
                menubar: false,
                language: _vm.editorLanguage,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic underline strikethrough | table image link | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent | removeformat',
              },"placeholder":_vm.$t('Write a text')},model:{value:(_vm.onboardingItem.Texts[lang]),callback:function ($$v) {_vm.$set(_vm.onboardingItem.Texts, lang, $$v)},expression:"onboardingItem.Texts[lang]"}}):_vm._e()]})],2)],1)],1)],1)],1):_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-select',{staticClass:"mb-4 mw-lang",attrs:{"items":_vm.languages,"label":_vm.$t('Language'),"hide-details":"auto"},model:{value:(_vm.currentLanguage),callback:function ($$v) {_vm.currentLanguage=$$v},expression:"currentLanguage"}})],1),_c('v-col',{attrs:{"cols":"11"}},[_vm._l((_vm.languages),function(lang,index){return [(lang == _vm.currentLanguage)?_c('v-text-field',{key:index,attrs:{"placeholder":_vm.$t('Write the name of the component')},model:{value:(_vm.onboardingItem.Components[lang]),callback:function ($$v) {_vm.$set(_vm.onboardingItem.Components, lang, $$v)},expression:"onboardingItem.Components[lang]"}}):_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }