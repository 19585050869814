<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          elevation="0"
          outlined
          @drop.prevent="onDrop($event)"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
          :height="options ? options.height : 150"
        >
          <v-layout align-center justify-center fill-height>
            <v-card-text>
              <v-row
                v-if="!multiple && uploadedFiles.length ? false : true"
                class="d-flex flex-column"
                dense
                align="center"
                justify="center"
              >
                <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="40">
                  mdi-cloud-upload
                </v-icon>
                <p>
                  <a @click="$refs['file'].click()">{{
                    $t("Drop your file(s) here, or click to select them.")
                  }}</a>
                </p>
              </v-row>
              <v-row v-if="!multiple && uploadedFiles.length">
                <v-col cols="11">
                  <v-img
                    contain
                    max-height="350"
                    max-width="700"
                    :src="fileSrc"
                  ></v-img>
                </v-col>
                <v-col cols="1">
                  <v-btn icon @click="deleteFile()">
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-virtual-scroll
                v-if="uploadedFiles.length > 0 && multiple"
                :items="uploadedFiles"
                height="150"
                item-height="50"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :key="item.name">
                    <v-list-item-content>
                      <v-list-item-title class="mr-4">
                        <v-row no-gutters>
                          <v-col>
                            <v-layout fill-height align-center>
                              {{ item.name }}
                            </v-layout>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-layout>
                        <v-btn
                          :loading="loadingDelete"
                          @click.stop="removeFile(item)"
                          icon
                        >
                          <v-icon> mdi-close-circle </v-icon>
                        </v-btn>
                      </v-layout>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>
                </template>
              </v-virtual-scroll>
            </v-card-text>
          </v-layout>
        </v-card>

        <input
          ref="file"
          type="file"
          :multiple="multiple"
          style="display: none"
          label="upload file"
          :accept="accepts"
          @change="loadFiles($event)"
        />
      </v-col>
      <v-col cols="12" class="mt-4 text-right" v-if="needsUploadBtn">
        <v-btn
          :disabled="uploadedFiles.length == 0"
          @click="uploadCropedLogo()"
        >
          {{ uploadBtnName }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
export default {
  props: [
    "usage",
    "ticketId",
    "parentFiles",
    "multiple",
    "uploadBtnName",
    "uploadUrl",
    "accepts",
    "needsUploadBtn",
    "autoUploadAfterSelect",
    "options",
  ],
  data() {
    return {
      dragover: null,
      uploadedFiles: [],
      filesName: [],
      keyitem: null,
      loadingDelete: null,
      fileSrc: null,
      loadingItemInNewTab: null,
    };
  },
  watch: {
    parentFiles(newValue) {
      this.uploadedFiles = [];
      if (newValue) {
        if (newValue.length > 0) {
          for (let i = 0; i < newValue.length; i++) {
            this.uploadedFiles.push({ name: newValue[i] });
          }
        }
      }
    },
  },
  created() {
    if (this.parentFiles) {
      if (this.parentFiles.length > 0) {
        for (let i = 0; i < this.parentFiles.length; i++) {
          this.uploadedFiles.push({ name: this.parentFiles[i] });
        }
      }
    }
  },

  methods: {
    deleteFile() {
      this.fileSrc = null;
      this.uploadedFiles = [];
      this.filesName = [];
    },
    uploadCropedLogo() {
      //let formData = new FormData();
      ///formData.append("file", this.favicon.file);
      let formData = [];
      this.uploadedFiles.forEach((element, index) => {
        formData[index] = new FormData();
        formData[index].append("file", element);
        this.$request.post(this.uploadUrl, formData[index], (res) => {
          if (res.success) {
            this.uploadedFiles[index] = null;
            this.$emit("setSnackbar", {
              snackbarText: this.$t("Image successfully uploaded"),
              snackbarColor: "success",
            });
          } else {
            this.snackbar = true;
            if (res.title) {
              this.$emit("setSnackbar", {
                snackbarText: res.title,
                snackbarColor: "red",
              });
            } else {
              this.$emit("setSnackbar", {
                snackbarText: this.$t(
                  "Something went wrong while uploading, please try again."
                ),
                snackbarColor: "red",
              });
            }
          }
        });
      });
      
    },
    removeFile(file) {
      this.loadingDelete = true;
      for (const key in this.uploadedFiles) {
        if (this.uploadedFiles[key].name == file.name) {
          this.$delete(this.uploadedFiles, key);
          this.loadingDelete = false;
        }
      }
    },
    removeFileOutOfArray(fileName) {
      // Find the index of the
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1) this.uploadedFiles.splice(index, 1);
      //update parent component
      this.$emit("deleteItemOutOfFile", { use: this.usage, value: fileName });
      this.loadingDelete = false;
    },

    loadFiles(e) {
      let formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("files", e.target.files[i]);
        this.filesName.push(e.target.files[i].name);
        this.uploadedFiles.push(e.target.files[i]);
        const file = e.target.files[i];
        // Ensure that you have a file before attempting to read it
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.fileSrc = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      }
      if (this.autoUploadAfterSelect) {
        this.uploadCropedLogo();
      }
      this.$emit("returnFilename", this.filesName);
    },
    onDrop(e) {
      this.dragover = false;
      let formData = new FormData();
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        formData.append("files", e.dataTransfer.files[i]);
        this.filesName.push(e.dataTransfer.files[i].name);
        this.uploadedFiles.push(e.dataTransfer.files[i]);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileSrc = e.target.result;
        };
        reader.readAsDataURL(e.dataTransfer.files[i]);
      }
      if (this.autoUploadAfterSelect) {
        this.uploadCropedLogo();
      }
      this.$emit("returnFilename", this.filesName);
    },

    getUrlFromItem(item) {
      return URL.createObjectURL(item);
    },
  },
};
</script>
  
  <style >
</style>